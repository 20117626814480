import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config";
import { userActions } from "../Slices/UserSlice";

export const postRatingThunk = createAsyncThunk(
  "create-rate",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;

    let response = null;
    try {
      response = await axios.post(`${BASE_URL}/rating`, payload, {
        headers: {
          Authorization: `Bearer ${AuthToken}`,
        },
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
      thunk.dispatch(userActions.login(null));
    }

    return response;
  }
);

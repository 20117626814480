import { createSlice } from "@reduxjs/toolkit";
import { getTrialsThunk, postTrialsThunk } from "../Thunk/TrialsThunk";
import { response_status } from "../../Constant/Status";

const initialState = {
  data: [],
  loading: false,
  status: null,
  postStatus: null,
  dataLength: 0,
};

const trialsSlice = createSlice({
  name: "trials",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // [1]  get Trials
    builder.addCase(getTrialsThunk.fulfilled, (state, action) => {
      state.status = response_status.success;
      state.loading = false;
      state.data = action.payload.data.map((v) => {
        v.createdAt = new Date().toLocaleDateString();
        return v;
      });
      state.dataLength = action.payload.dataLength;
    });
    builder.addCase(getTrialsThunk.pending, (state, action) => {
      state.loading = true;
      state.data = [];
      state.status = response_status.none;
    });
    builder.addCase(getTrialsThunk.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.status = response_status.fail;
    });

    builder.addCase(postTrialsThunk.fulfilled, (state, action) => {
      state.postStatus = response_status.success;
      state.loading = false;
    });
    builder.addCase(postTrialsThunk.pending, (state, action) => {
      state.loading = true;
      state.postStatus = response_status.none;
    });
    builder.addCase(postTrialsThunk.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.postStatus = response_status.fail;
    });
  },
});

export default trialsSlice.reducer;
export const trialAction = trialsSlice.actions;

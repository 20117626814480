import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config";
import { userActions } from "../Slices/UserSlice";

export const getInstructionsThunk = createAsyncThunk(
  "getInstructions",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthThunk = state.user.user.token;

    const response = await axios.get(`${BASE_URL}/instructions`, {
      params: {
        skip: payload.skip,
        limit: payload.limit,
      },
      headers: {
        Authorization: `Bearer ${AuthThunk}`,
      },
    });
    return response.data;
  }
);

export const deleteInstructionsThunk = createAsyncThunk(
  "delteInstructions",
  async (id, thunk) => {
    const state = thunk.getState();
    const AuthThunk = state.user.user.token;
    let response = null;
    try {
      response = await axios.delete(`${BASE_URL}/instructions/${id}`, {
        headers: {
          Authorization: `Bearer ${AuthThunk}`,
        },
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
      thunk.dispatch(userActions.login(null));
    }
    return response.data;
  }
);

export const addInstructionsThunk = createAsyncThunk(
  "addInstructions",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthThunk = state.user.user.token;
    let response = null;
    try {
      response = await axios.post(`${BASE_URL}/instructions`, payload, {
        headers: {
          Authorization: `Bearer ${AuthThunk}`,
        },
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
      thunk.dispatch(userActions.login(null));
    }
    return response.data;
  }
);

export const editInstructionsThunk = createAsyncThunk(
  "editInstructions",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthThunk = state.user.user.token;
    let response = null;
    try {
      response = await axios.put(
        `${BASE_URL}/instructions/${payload.id}`,
        payload.body,
        {
          headers: {
            Authorization: `Bearer ${AuthThunk}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
      thunk.dispatch(userActions.login(null));
    }
    return response.data;
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config";

export const createCouponeThunk = createAsyncThunk(
  "create-coupone",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    let response = null;
    try {
      response = await axios.post(`${BASE_URL}/coupons`, payload, {
        headers: {
          Authorization: `Bearer ${AuthToken}`,
        },
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
    }
    return response.data;
  }
);

export const deleteCouponeThunk = createAsyncThunk(
  "delete-coupone",
  async (id, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    let response = null;
    try {
      response = await axios.post(`${BASE_URL}//coupons/product/${id}`, {
        headers: {
          Authorization: `Bearer ${AuthToken}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
    return response.data;
  }
);

import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../../config";
import {userActions} from "../Slices/UserSlice";
import {usersActions} from "../Slices/UsersSlice";

export const getUsersByRole = createAsyncThunk(
  "getUsersByRole",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    let response = null;
    try {
      response = await axios.post(
        `${BASE_URL}/users`,
        {
          role: [...payload.role],
        },
        {
          withCredentials: true,
          params: {
            skip: payload.skip,
            limit: payload.limit,
          },

          headers: {
            Authorization: `Bearer ${AuthToken}`,
          },
        },
      );
    } catch (err) {
      console.log(err);
      thunk.dispatch(userActions.login(null));
    }
    return response.data;
  },
);

export const getUsersById = createAsyncThunk(
  "getUsersById",
  async (id, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    let response = null;
    try {
      response = await axios.get(`${BASE_URL}/users/${id}`, {
        headers: {
          Authorization: `Bearer ${AuthToken}`,
        },
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
      thunk.dispatch(userActions.login(null));
    }
    return response.data;
  },
);

export const createUsersThunk = createAsyncThunk(
  "createUser",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    let response = null;
    try {
      response = await axios.post(`${BASE_URL}/signup`, payload, {
        headers: {
          Authorization: `Bearer ${AuthToken}`,
        },
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
      return err;
      // thunk.dispatch(userActions.login(null));
    }
    return response.data;
  },
);

export const DeleteUserByIdThunk = createAsyncThunk(
  "deleteuser",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    let response = null;

    try {
      thunk.dispatch(usersActions.deleteUserById(payload));
      response = await axios.delete(`${BASE_URL}/users/${payload}`, {
        headers: {
          Authorization: `Bearer ${AuthToken}`,
        },
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
      thunk.dispatch(userActions.login(null));
    }
    return response.data;
  },
);

import React from "react";
import {Link} from "react-router-dom";
import {motion} from "framer-motion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ContactItem = (props) => {
  return (
    <motion.div
      animate={{
        x: props.xAnimate ? props.xAnimate : 0,
        y: props.yAnimate ? props.yAnimate : 0,
      }}
      initial={
        (props?.xInitial ? {x: props?.xInitial} : {},
        props?.yInitial ? {y: props?.yInitial} : {})
      }
      transition={{type: "spring", delay: 0.45}}
    >
      <Link
        to={props.path ?? "/contact"}
        target="_blank"
        className="contact-item"
      >
        <div
          className={
            props.column ? "contact-item__top column" : "contact-item__top"
          }
        >
          <FontAwesomeIcon
            icon={props?.icon}
            name={props?.title}
            className="contact-item__top__icon"
          />
          <div
            className={[
              props.dark
                ? "contact-item__top__title dark column"
                : "contact-item__top__title",
            ]}
          >
            {props.title}
          </div>
        </div>
        <div className="contact-item__bottom">
          {!props.children ? (
            <>
              <p
                className={`contact-item__bottom__desc ${props?.dark}  `}
                style={{textAlign: props?.textAlign}}
              >
                {props.desc}
              </p>
              <p
                className={`contact-item__bottom__desc ${props?.dark}  `}
                style={{textAlign: props?.textAlign}}
              >
                {props.secDesc}
              </p>
            </>
          ) : (
            props.children
          )}
        </div>
      </Link>
    </motion.div>
  );
};

export default React.memo(ContactItem);

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config";
import { userActions } from "../Slices/UserSlice";

export const singOutThunk = createAsyncThunk(
  "singout",
  async (state, thunk) => {
    let response = null;
    try {
      response = await axios.get(BASE_URL + "/logout", {
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
      thunk.dispatch(userActions.login(null));
    }
    return response.data;
  }
);

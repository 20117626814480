import React from "react";
import {motion} from "framer-motion";
import {Link} from "react-router-dom";

const NavbarLink = ({route, location, onClick, name, path}) => {
  return (
    <>
      <motion.li
        animate={{x: 0, opacity: 1}}
        initial={{x: -150, opacity: 0}}
        transition={{delay: 0.5, duration: 0.4, type: "spring"}}
        className="navbarItem"
        onClick={onClick}
      >
        <Link
          to={route?.path ?? path}
          className={`navbarItem-link ${
            location === route?.path && "active"
          }`}
        >
          {route?.name ?? name}
        </Link>
      </motion.li>
    </>
  );
};

export default React.memo(NavbarLink);

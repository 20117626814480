import { createSlice } from "@reduxjs/toolkit";
import { postRatingThunk } from "../Thunk/RatingThunk";
import { response_status } from "../../Constant/Status";

const initialState = {
  data: null,
  loading: false,
  status: null,
};
const ratingSlice = createSlice({
  name: "rating",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postRatingThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.status = response_status.success;
    });
    builder.addCase(postRatingThunk.pending, (state, action) => {
      state.loading = true;
      state.status = response_status.none;
    });
    builder.addCase(postRatingThunk.rejected, (state, action) => {
      state.loading = false;
      state.status = response_status.fail;
    });
  },
});

export default ratingSlice.reducer;
export const ratingAction = ratingSlice.actions;

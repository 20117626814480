import React from "react";
import ROUTES from "../../Routes/Routes";
import Logo from "../../Assets/Logo.svg";
import {Link, useLocation} from "react-router-dom";
import SocialLinks from "../../components/SocialLinks/SocialLinks";
import MobileSideNav from "../../components/MobileSideNav/MobileSideNav";
import {motion} from "framer-motion";
import {faBars, faClose} from "@fortawesome/free-solid-svg-icons";
import RolesLayout from "../RolesLayout/RolesLayout";
import {ROLES} from "../../Constant/Roles";
import Button from "../../components/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../../Redux/Slices/UserSlice";
import NavbarLink from "./NavbarLink";

const Navbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [navstate, setNavState] = React.useState(false);
  const pathname = location.pathname;

  const User = useSelector((state) => state?.user?.user?.token);

  // Get userToken
  const navAuth = () => {
    if (User) {
      dispatch(userActions.logOut());
    }
  };
  // Scroll To Bottom
  const handleClickScroll = (section) => {
    const element = document.getElementById(section);

    if (element) {
      const scroll = element.id === "products" ? "start" : "center";

      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        behavior: "smooth",
        block: scroll,
        inline: "center",
      });
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <motion.div
          animate={{y: 0, opacity: 1}}
          initial={{y: -150, opacity: 0}}
          transition={{delay: 0.5, duration: 0.4, type: "spring"}}
          className=" z-30"
        >
          <Link to="/">
            <img src={Logo} alt="Wafitop" className="navbar-left__logo" />
          </Link>
        </motion.div>

        {/* Mobile Side navbar */}
        <MobileSideNav isActive={navstate} setNavState={setNavState} />

        <ul className="navbar-left__menu">
          {ROUTES?.map((route) => {
            return (
              !route.isHidden && (
                <NavbarLink
                  key={route.id}
                  route={route}
                  location={pathname}
                  onClick={() => handleClickScroll(route.id)}
                />
              )
            );
          })}

          {/* Drop down Styling on navbarlink.scss file */}
          {/* <DropDownLink
            title={"كن شريكاً"}
            active={dropDown}
            onClick={() => setDropDown((old) => !old)}
          >
            <Link to="/contact" className="dropdown__link-item">
              <span className="dropdown__link-item__title">شريك ترويجى</span>
              <p className="dropdown__link-item__desc">
                انضم الى شركاء واس وساعد مؤسستك فى النمو , احصل على عموله جيده
                مع كل عميل تقنعه بتجربة النظام
              </p>
            </Link>
            <Link to="/contact" className="dropdown__link-item">
              <span className="dropdown__link-item__title">موزعى واس</span>
              <p className="dropdown__link-item__desc">
                الموزع للنظام هو شريك لنا يعمل على بيع خدمات الشركة فى السوق
                السعودى
              </p>
            </Link>
          </DropDownLink> */}
          {/* Display Admin page based on  user Role role  */}
          <RolesLayout roles={[ROLES.ADMIN, ROLES.EMPLOYER]}>
            <NavbarLink path="/admin" name="صفحه الأدمن" location={location} />
          </RolesLayout>

          <div style={{marginRight: "2rem", alignSelf: "center"}}>
            <Button
              onClick={navAuth}
              title={User ? "تسجيل خروج" : "تسجيل دخول"}
              styles="btn  btn--primary btn--m btn--rounded-s py-3"
              path={User ? "#" : "/login"}
            />
          </div>
        </ul>
      </div>
      {/* Mobile Right Navbar */}
      <div className="navbar-right">
        <Button
          icon={navstate ? faClose : faBars}
          styles={" navbar-right__mobile-menu"}
          name="mobile-menu"
          onClick={() => setNavState(!navstate)}
        />

        <div className="navbar-right__social">
          <SocialLinks backgroundColor={"var(--color-black-light)"} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

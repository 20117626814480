import { faCircleCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const green = "green";
const danger = "red";

export const renderPlanCells = (title, basic, advanced, full) => {
  return {
    plan: title,
    basic: !basic.renderCell ? (
      <span>
        <FontAwesomeIcon
          icon={basic.check ? faCircleCheck : faX}
          color={basic.check ? green : danger}
        />
        <br />
        {basic?.text ?? ""}
      </span>
    ) : (
      basic.renderCell()
    ),
    advanced: !advanced.renderCell ? (
      <span>
        <FontAwesomeIcon
          icon={advanced.check ? faCircleCheck : faX}
          color={advanced.check ? green : danger}
        />
        <br />
        {advanced?.text ?? ""}
      </span>
    ) : (
      advanced.renderCell()
    ),
    full: !full.renderCell ? (
      <span>
        <FontAwesomeIcon
          icon={full.check ? faCircleCheck : faX}
          color={full.check ? green : danger}
        />
        <br />
        {full?.text ?? ""}
      </span>
    ) : (
      full.renderCell()
    ),
  };
};

export const pricingData = [
  renderPlanCells(
    "نقاط البيع (اوفلاين)",
    { check: true },
    { check: true },
    { check: true }
  ),

  renderPlanCells(
    "نقاط البيع (اونلاين) و عدد المستخدمين",
    { checK: false },
    { check: true, text: "عدد 3 مستخدمين كحد اقصى" },
    { check: true, text: "    من 3 الى 5 مستخدمين" }
  ),

  renderPlanCells(
    "قائمة الأسعار",
    { check: true },
    { check: true },
    { check: true }
  ),

  renderPlanCells(
    " العروض علي المبيعات",
    { check: false },
    { check: true },
    { check: true }
  ),
  renderPlanCells(
    " العروض علي الأسعار",
    { check: false },
    { check: true },
    { check: true }
  ),
  renderPlanCells(
    " العملاء و الموردين",
    { check: true },
    { check: true },
    { check: true }
  ),
  renderPlanCells(
    " كشف الحساب",
    { check: true },
    { check: true },
    { check: true }
  ),
  renderPlanCells(
    "الدليل المحاسبى",
    { check: true },
    { check: true },
    { check: true }
  ),
  renderPlanCells(
    " الحسابات الختاميه مثل قائمة الدخل و ميزان المراجعه و قائمه المركز المالي",
    { check: true },
    { check: true },
    { check: true }
  ),
  renderPlanCells(
    " إضافة الأصناف بالمخزن",
    { check: true },
    { check: true },
    { check: true }
  ),
  renderPlanCells(
    " متابعه حركه المخزون",
    { check: false },
    { check: true },
    { check: true }
  ),
  renderPlanCells(
    " سندات الصرف و القبض",
    { check: true },
    { check: true },
    { check: true }
  ),
  renderPlanCells(
    " القيود اليومية و مراكز التكلفة",
    { check: false },
    { check: true },
    { check: true }
  ),
  renderPlanCells(
    " إدارة الموارد البشرية و الأجور و المرتبات و إدارة الحضور و الأنصراف",
    { check: false },
    { check: false },
    { check: true }
  ),
  renderPlanCells(
    " إضافه الفروع ",
    { check: false },
    { check: true, text: "عدد 3 فروع كحد أقصي" },
    { check: true, text: "لا محدود" }
  ),
  renderPlanCells(
    " إرسال الرسائل النصية ",
    { check: false },
    { check: false },
    { check: true, text: "إختيارية بإضافة 50 ريال شهرياً" }
  ),
  renderPlanCells(
    " الأصول الثابتة ",
    { check: false },
    { check: true, text: "إختيارية بإضافة 50 ريال شهرياً" },
    { check: true, text: "إختيارية بإضافة 50 ريال شهرياً" }
  ),
  renderPlanCells(
    " الصادر و الوارد و الأرشفه الإليكترونية ",
    { check: false },
    { check: true, text: "إختيارية بإضافة 50 ريال شهرياً" },
    { check: true, text: "إختيارية بإضافة 50 ريال شهرياً" }
  ),
];

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { animate, motion, useAnimation, useInView } from "framer-motion";
import React from "react";

const Counter = (props) => {
  const nodeRef = React.useRef();
  const isInView = useInView(nodeRef, { once: true });
  const mainControls = useAnimation();

  React.useEffect(() => {
    const node = nodeRef.current;
    if (isInView) {
      mainControls.start("start");
    }
    const animateNumbers = animate(props.from, props?.to, {
      duration: 2,
      delay: 0.5,
      onUpdate(value) {
        node.textContent = props?.percentage
          ? Math.ceil(value.toFixed()) < 97
            ? 97
            : Math.ceil(value.toFixed()) + "%"
          : Math.ceil(value.toFixed());
      },
    });

    return () => animateNumbers.stop();
  }, [
    isInView,
    mainControls,
    props.from,
    props?.percentage,
    props?.to,
    props.value,
  ]);

  return (
    <div className="counter">
      <div className="counter__content">
        <div className="counter__icon">
          <FontAwesomeIcon
            icon={props.icon}
            name={props.title}
            className="counter__icon-icon"
          />
          <h2 className="counter__icon-title">{props.title}</h2>
        </div>
        <motion.span className="counter__value" ref={nodeRef} />
      </div>
    </div>
  );
};

export default React.memo(Counter);

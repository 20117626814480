import React from "react";

const Switch = ({ isChecked, toggleSwitch }) => {
  return (
    <label className="flex items-center cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          className="hidden"
          checked={isChecked}
          onChange={toggleSwitch}
        />
        <div
          className={`toggle__line w-10 h-6 ${
            isChecked ? "bg-[gold]" : "bg-gray-400"
          } rounded-full shadow-inner`}
        ></div>
        <div
          className={`toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 ${
            isChecked ? " left-0" : "right-0"
          }`}
        ></div>
      </div>
    </label>
  );
};

export default Switch;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function GoalsItem(props) {
  return (
    <div className="">
      <div className="flex gap-3 items-center">
        <FontAwesomeIcon
          icon={props?.icon}
          className="text-4xl lg:text-5xl text-[gold] p-2 bg-white h-12 w-12 rounded-full"
        />
        <h2 className="text-4xl font-bold  text-white">{props.title}</h2>
      </div>
      <div className="mt-3">
        <p className="text-bold text-xl text-gray-300">{props.text}</p>
      </div>
    </div>
  );
}

export default React.memo(GoalsItem);

import { createSlice } from "@reduxjs/toolkit";
import { createFaqThunk, deleteFaqThunk, getFAQThunk } from "../Thunk/FAQTHunk";
import { response_status } from "../../Constant/Status";

const initialState = {
  data: [],
  loading: false,
  status: null,
  deleteStatus: null,
  createStatus: null,
};

const FAQSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    setStatus: (state, action)=>{
      state.status = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFAQThunk.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.loading = false;
      state.status = response_status.success;
    });
    builder.addCase(getFAQThunk.pending, (state, action) => {
      state.loading = true;
      state.status = response_status.none;
    });
    builder.addCase(getFAQThunk.rejected, (state, action) => {
      state.data = [];
      state.loading = false;
      state.status = response_status.fail;
    });
    // [2] Delete FAQ
    builder.addCase(deleteFaqThunk.fulfilled, (state, action) => {
      state.data = state.data.filter((item) => item.id !== action.payload);
      state.loading = false;
      state.deleteStatus = response_status.success;
    });
    builder.addCase(deleteFaqThunk.pending, (state, action) => {
      state.loading = true;
      state.delteStatus = response_status.none;
    });
    builder.addCase(deleteFaqThunk.rejected, (state, action) => {
      state.data = [];
      state.loading = false;
      state.delteStatus = response_status.fail;
    });
    // [3] create FAQ
    builder.addCase(createFaqThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.createStatus = response_status.success;
    });
    builder.addCase(createFaqThunk.pending, (state, action) => {
      state.loading = true;
      state.createStatus = response_status.none;
    });
    builder.addCase(createFaqThunk.rejected, (state, action) => {
      state.loading = false;
      state.createStatus = response_status.fail;
    });
  },
});

export default FAQSlice.reducer;
export const FaqAction = FAQSlice.actions;

import React from "react";
import {useSelector} from "react-redux";

const RolesLayout = (props) => {
  const userSliceData = useSelector((s) => s.user);
  if (!props?.roles?.some((v) => userSliceData.user?.user?.role?.includes(v))) {
    return <React.Fragment></React.Fragment>;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default RolesLayout;

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config";
import { userActions } from "../Slices/UserSlice";
// import axios from "axios";
export const getChatThunk = createAsyncThunk("getChat", async (id, thunk) => {
  const state = thunk.getState();
  const AuthToken = state.user.user.token;

  const response = await axios.get(`${BASE_URL}/tickets/${id}`, {
    headers: {
      Authorization: `Bearer ${AuthToken}`,
    },
    withCredentials: true,
  });
  return response.data;
});

export const addChatThunk = createAsyncThunk(
  "addChat",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    let response = null;
    try {
      response = await axios.post(`${BASE_URL}/replays/`, payload, {
        headers: {
          Authorization: `Bearer ${AuthToken}`,
        },
      });
    } catch (err) {
      thunk.dispatch(userActions.login(null));
    }
    return response.data;
  }
);

export const setChatSeen = createAsyncThunk(
  "setChatSeen",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    const { user } = state.user.user;
    let data = [];
    const msgList = payload.data.filter(
      (v) => !v.replay_seen && v.replay_id && v.replay_sender !== user?.userId
    );
    msgList.map((v) => {
      return axios({
        url: `${BASE_URL}/replays/seen/${v.replay_id}`,
        method: "PUT",
        data: {
          ...v,
          seen: true,
          replay_seen: true,
        },
        headers: { Authorization: `Bearer ${AuthToken}` },
      });
    });
    data = msgList.map((v) => {
      if (!v.replay_seen && v.replay_id && v.replay_sender !== user?.userId) {
        return { ...v, replay_seen: true };
      }
      return v;
    });
    return data;
  }
);

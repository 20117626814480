import SocialLinks from "../../components/SocialLinks/SocialLinks";
import Logo from "../../Assets/Logo.svg";
import {Link} from "react-router-dom";
import FooterList from "../../components/FooterList/FooterList";
import {FooterData} from "../../Data/FooterData";
import {motion} from "framer-motion";
import {memo} from "react";
const Footer = () => {
  return (
    <>
      <motion.footer
        className="footer"
        animate={{opacity: 1, y: 0}}
        initial={{y: 300, opacity: 0}}
        transition={{duration: 0.4, delay: 0.2}}
      >
        <div className="footer-center">
          <div className="footer-center__logo">
            <Link to="/" className="footer-center__logo-img">
              <img src={Logo} alt="WAFI-TOP" />
            </Link>
          </div>
          <div className="mb-5">
          <SocialLinks />
          </div>
          <div className="">
            {FooterData.map((item, i) => {
              return (
                <FooterList
                  title={item.title}
                  key={item.title + i}
                  links={item.links}
                />
              );
            })}
          </div>
          {/* <iframe
            title="location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3788.0107146966197!2d42.6861393748532!3d18.301118182750386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15fb574792e1b093%3A0x5b19ae050f60da40!2z2LTYsdmD2Kkg2YjYp9mB2Yog2KfZhNmC2YXYqQ!5e0!3m2!1sen!2ssa!4v1686403918241!5m2!1sen!2ssa"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="footer-center-iframe"
          /> */}
          {/* <Link to="/" className="footer-privacy">
            السياسات و الخصوصية
          </Link> */}
        </div>
        <div className="footer-bottom">
          <p className="footer-bottom__text">
            جميع الحقوق &copy; محفوظه لشركة وافى القمه
          </p>
        </div>
      </motion.footer>
    </>
  );
};

export default memo(Footer);

import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const OurClients = (props) => {
  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    // centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="text-center pb-4">
      <h2 className="font-bold text-4xl mb-2 text-white">
        {props?.title}
        <span className="text-[gold]"> {props.coloredTitle} </span>
      </h2>
      <p className="text-gray-300 w-[80%] lg:w-[50%] m-auto text-xl">
        {props?.description}
      </p>

      <Slider className="ourClients__imgs" {...settings}>
        {props.imgs.map((img, i) => {
          return (
            <img
              src={img}
              key={i}
              alt={`img${i}`}
              className="ourClients__imgs__img"
            />
          );
        })}
      </Slider>
    </div>
  );
};

export default React.memo(OurClients);

import React from "react";
import Switch from "../../components/Switch/Switch";
import Table from "../../components/Table";
import {pricingData, renderPlanCells} from "../../Data/PricingData";
import {useDispatch} from "react-redux";
import {contactAction} from "../../Redux/Slices/ContactSlice";
import {Link} from "react-router-dom";

const PricingPage = () => {
  const dispatch = useDispatch();
  const [monthly, setMonthly] = React.useState(false);
  const toggleSwitch = () => {
    setMonthly((old) => !old);
  };

  const columns = [
    {Header: `الباقات و الأسعار`, accessor: "plan"},
    {
      Header: ` Basic الأساسية ${monthly ? "75.00" : "900"} ريال  ${
        monthly ? "شهرياً" : "سنوياً"
      }`,
      accessor: "basic",
    },
    {
      Header: ` Advanced المتقدمة ${monthly ? "100" : "1200"} ريال  ${
        monthly ? "شهرياً" : "سنوياً"
      }`,
      accessor: "advanced",
    },
    {
      Header: ` Full الشاملة ${monthly ? "150" : "1800"} ريال  ${
        monthly ? "شهرياً" : "سنوياً"
      }`,
      accessor: "full",
    },

    // Add more columns as needed
  ];

  const setChoosenPlan = (item) => {
    const id = item.target?.id;
    dispatch(contactAction.setChosenPlan(id));
  };

  const TableContent = [
    ...pricingData,
    renderPlanCells(
      " اختر باقتك  ",
      {
        renderCell: () => {
          return (
            <input
              type="radio"
              name="plan"
              id="basic"
              onChange={(e) => setChoosenPlan(e)}
            />
          );
        },
      },
      {
        renderCell: () => {
          return (
            <input
              type="radio"
              name="plan"
              id="advanced"
              onChange={(e) => setChoosenPlan(e)}
            />
          );
        },
      },
      {
        renderCell: () => {
          return (
            <input
              type="radio"
              name="plan"
              id="full"
              onChange={(e) => setChoosenPlan(e)}
            />
          );
        },
      },
    ),
  ];

  return (
    <div className="max-w-[1150px]  mx-auto my-[7rem] lg:my-[10rem] px-5">
      {/* Header */}
      <div className="grid gird-cols-1 lg:grid-cols-2 gap-4">
        <div>
          <h1 className="text-3xl lg:text-7xl font-bold  mb-3 text-white">
            ابدأ <span className="text-[gold]">مجاناً،</span> <br /> واختر خطتك
            لاحقاً!
          </h1>
          <div className="flex items-center gap-3">
            <p className="text-gray-300 text-xl font-semibold">
              الدفع {!monthly ? "شهرياً" : "سنوياً"}{" "}
              <span
                className={`${!monthly ? "text-gray-300" : "line-through"}`}
              >
                (خصم يصل إلى 30%)
              </span>
            </p>
            <Switch isChecked={!monthly} toggleSwitch={toggleSwitch} />
            <span span className="text-[gold] text-xl">
              الدفع {monthly ? "شهرياً" : "سنوياً"}
            </span>
          </div>
        </div>
        <div className=" lg:max-w-[80%]">
          <h2 className="text-3xl lg:text-7xl font-bold  mb-3 text-white">
            14 يوم فترة تجريبية
          </h2>
          <p className="text-gray-300 text-xl font-semibold mt-3 mb-1">
            لا حاجة لبطاقة ائتمان ، قم بإلغاء الحساب في أي وقت.
          </p>
          <p className="text-gray-300 text-xl font-semibold">
            ستمنحك نسخة تجريبية مدتها 14 يومًا حسابًا مجانيًا ، وعندما تنتهي
            الفترة التجريبية ، يمكنك ترقية نفس الحساب إلى الباقة المناسبة لك.
          </p>
        </div>
      </div>
      {/* tables  */}
      <div className="my-10">
        <Table data={TableContent} columns={columns} />
      </div>
      {/* Contact us Button */}

      <Link
        path="/contact"
        className="btn btn--primary btn--l  block max-w-[50%] mx-auto"
      >
        أرسل لنا
      </Link>
    </div>
  );
};

export default PricingPage;

import React from "react";
import {Link} from "react-router-dom";
import RolesLayout from "../../layouts/RolesLayout/RolesLayout";
import {ROLES} from "../../Constant/Roles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPen, faTrash} from "@fortawesome/free-solid-svg-icons";
import {BASE_URL} from "../../config";
import {undefinedImage} from "../../Images";
import ImageUploadComponent from "../ImageUploadComponent";
import useFileUpload from "../../hooks/UseFileUpload";

const ProductCard = ({
  product,
  handleEdit,
  onDelete,
  onSubmit,
  category,
  loading,
  onEdit,
}) => {
  const titleRef = React.useRef(null);
  const descriptionRef = React.useRef(null);
  const [editable, setEditable] = React.useState(false);
  const {title, description, discount, poster, id} = product;
  const {data, message, progress, uploadFile} = useFileUpload(
    `/uploads`,
    "poster",
  );
  // price included in props
  const image = `${BASE_URL.replace("/api", "")}${poster}`;
  return (
    <div className="product-card">
      {discount && (
        <div className="product-card__coupon">
          <span className="product-card__coupon__text">
            قيمه الخصم {discount} ريال
          </span>
        </div>
      )}
      <div className="product-card__top">
        {!editable && (
          <img
            crossOrigin="true"
            src={image ?? undefinedImage}
            alt={title}
            loading="lazy"
            className="product-card__top-img"
          />
        )}
        {editable && (
          <ImageUploadComponent
            onChange={uploadFile}
            progress={progress}
            img={data?.poster}
            msg={message}
          />
        )}
        <h2
          className="product-card__top-title"
          ref={titleRef}
          contentEditable={editable}
          name="title"
        >
          {title}
        </h2>
      </div>
      <div className="product-card__body">
        <h5 className="product-card__body-title">معلومات:</h5>
        <p
          className="product-card__body-description max-h-[2rem] overflow-hidden "
          contentEditable={editable}
          name="description"
          ref={descriptionRef}
          dangerouslySetInnerHTML={{__html: description}}
        />
      </div>
      <div className="product-card__footer">
        <div className="product-card__footer__categories">
          <div className="product-card__footer__categories__item">
            <Link
              className="product-card__footer__categories__item-link "
              to={`/products/${id}` ?? "/"}
            >
              المزيد
            </Link>
          </div>
          {/* <div className="product-card__footer__categories__item">
            <span className="product-card__footer__categories__item-text">
              {title ?? "WAS Accounting"}
            </span>
          </div> */}
        </div>
        <RolesLayout roles={[ROLES.ADMIN]}>
          <div className="product-card__adminActions">
            <button
              onClick={() => {
                setEditable((prev) => !prev);
              }}
              disabled={loading}
              className="btn btn--primary btn--m"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <button
              disabled={loading}
              className="btn btn--danger btn--m"
              onClick={() => onDelete(id)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
            {editable && (
              <button
                disabled={loading}
                className="btn btn--success btn--m"
                onClick={() =>
                  onSubmit(id, {
                    // ...product,
                    description: descriptionRef.current.textContent,
                    title: titleRef.current.textContent,
                    poster: data?.poster ?? "",
                  })
                }
              >
                <FontAwesomeIcon icon={faCheck} />
              </button>
            )}
          </div>
        </RolesLayout>
      </div>
    </div>
  );
};

export default React.memo(ProductCard);

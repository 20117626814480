import React from "react";
import {Link} from "react-router-dom";
import {motion} from "framer-motion";
import {socialMediaLinks} from "../../Constant/SocialLinks";

const SocialLinks = (props) => {
  return (
    <motion.div
      className="social"
      animate={{y: 0, opacity: 1}}
      initial={{y: -250, opacity: 0}}
      transition={{delay: 0.6}}
    >
      <Link
        to={socialMediaLinks.facebook.link}
        className="social-item"
        target="_blank"
        style={{backgroundColor: props?.backgroundColor}}
      >
        <span className="social-item__icon">
          {socialMediaLinks.facebook.icon}
        </span>
      </Link>
      <Link
        to={socialMediaLinks.snapchat.link}
        className="social-item"
        target="_blank"
        style={{backgroundColor: props?.backgroundColor}}
      >
        <span className="social-item__icon">
          {socialMediaLinks.snapchat.icon}
        </span>
      </Link>

      <Link
        to={socialMediaLinks.instagram.link}
        className="social-item"
        target="_blank"
        style={{backgroundColor: props?.backgroundColor}}
      >
        <span className="social-item__icon">
          {socialMediaLinks.instagram.icon}
        </span>
      </Link>

      <Link
        to={socialMediaLinks.whatsapp.link}
        target="_blank"
        className="social-item"
        style={{backgroundColor: props?.backgroundColor}}
      >
        <span className="social-item__icon">
          {socialMediaLinks.whatsapp.icon}
        </span>
      </Link>
    </motion.div>
  );
};

export default React.memo(SocialLinks);

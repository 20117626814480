import { createSlice } from "@reduxjs/toolkit";
import { createCouponeThunk, deleteCouponeThunk } from "../Thunk/CouponeThunk";
import { response_status } from "../../Constant/Status";

const initialState = {
  status: null,
  loading: false,
};

const couponeSlice = createSlice({
  name: "coupone",
  initialState,
  reducers: {
    setState: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCouponeThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.status = action.payload;
      state.status = response_status.success;
    });
    builder.addCase(createCouponeThunk.pending, (state, action) => {
      state.loading = true;
      state.status = null;
      state.status = response_status.none;
    });
    builder.addCase(createCouponeThunk.rejected, (state, action) => {
      state.loading = false;
      state.status = action.error.message;
      state.status = response_status.fail;
    });
    // [2] Delete Coupone Extra Reducers
    builder.addCase(deleteCouponeThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.status = action.payload;
      state.status = response_status.success;
    });
    builder.addCase(deleteCouponeThunk.pending, (state, action) => {
      state.loading = true;
      state.status = null;
      state.status = response_status.none;
    });
    builder.addCase(deleteCouponeThunk.rejected, (state, action) => {
      state.loading = false;
      state.status = action.error.message;
      state.status = response_status.fail;
    });
  },
});

export default couponeSlice.reducer;
export const couponeAction = couponeSlice.actions;

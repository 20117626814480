import {
  FaFacebook,
  FaInstagram,
  FaSnapchat,
  FaTiktok,
  FaWhatsapp,
} from "react-icons/fa";

export const socialMediaLinks = {
  facebook: {
    title: "facebook",
    link: "https://www.facebook.com/profile.php?id=61559265856227&locale=ar_AR",
    icon: <FaFacebook />,
  },
  instagram: {
    title: "instagram",
    link: "https://www.instagram.com/wafetop/",
    icon: <FaInstagram />,
  },
  snapchat: {
    title: "snapchat",
    link: "https://www.snapchat.com/add/devbayoumi?share_id=3MSsw162M6E&locale=ar-SA",
    icon: <FaSnapchat />,
  },
  tiktok: {
    title: "tiktok",
    link: "https://www.tiktok.com/@wafitopco?_t=8njhLb35hc0&_r=1",
    icon: <FaTiktok />,
  },
  whatsapp: {
    title: "tiktok",
    link: "https://wa.me/966546811900",
    icon: <FaWhatsapp />,
  },
};

import Button from "../../components/Button/Button";
import {useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/Loading/Loading";
import {BASE_URL} from "../../config";
import {useDispatch, useSelector} from "react-redux";
import {getProductByIdThunk} from "../../Redux/Thunk/ProductThunk";
import React from "react";

const ProductPage = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {currentProduct: data, loading} = useSelector((s) => s.products);

  React.useEffect(() => {
    if (!id) {
      navigate("/");
      return;
    }
    dispatch(getProductByIdThunk(id));
  }, [dispatch, id, navigate]);

  React.useEffect(() => {
    if (data === undefined && !loading) {
      navigate("/");
    }
  }, [loading, data, navigate]);

  const image = data?.poster
    ? `${BASE_URL.replace("/api", "")}${data?.poster}`
    : "";

  return (
    <section className="productPage">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="productPage__right">
            <div>
              <h1 className="productPage__right__title">{data?.title}</h1>
              {/* <span className="productPage__right__price">
                {data?.price ?? ""} SAR
              </span> */}
              <div className="productPage__right__rating">
                <div className="productPage__right__rating-star">
                  <FontAwesomeIcon
                    className="productPage__right__rating-start-item"
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    className="productPage__right__rating-start-item"
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    className="productPage__right__rating-start-item"
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    className="productPage__right__rating-start-item"
                    icon={faStar}
                  />
                  <FontAwesomeIcon
                    className="productPage__right__rating-start-item"
                    icon={faStar}
                  />
                </div>
                {/* <div className="productPage__right__rating-text">
                  6280 Review
                </div> */}
              </div>
            </div>
            <div
              className="productPage__right__description"
              dangerouslySetInnerHTML={{__html: data?.description}}
            />
            <div className="productPage__right__action">
              <Button
                title="طلب البرنامج"
                path="/contact"
                styles="btn btn--primary btn--m"
              />
            </div>
          </div>
          <div className="productPage__left">
            {
              <img
                crossOrigin="true"
                className="productPage__left__img"
                src={image}
                alt={data?.title}
              />
            }
          </div>
        </>
      )}
    </section>
  );
};

export default ProductPage;

import {createSlice} from "@reduxjs/toolkit";
import {
  createTicketThunk,
  getTicketByCustomerId,
  getTicketsThunk,
  setTicketSeenThunk,
  solveOrRejectTicketThunk,
} from "../Thunk/TicketsThunk";
import {response_status} from "../../Constant/Status";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell} from "@fortawesome/free-solid-svg-icons";

import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

const ticketStatusInArabic = (status) => {
  const s = status.toLowerCase();
  switch (s) {
    case "pending":
      return "قيد العمل";

    case "rejected":
      return "مرفوضه";

    case "solved":
      return "تم الحل";

    default:
      break;
  }
};

const initialState = {
  data: [],
  loading: false,
  status: null,
  dataLength: 0,
  seenStatus: null,
  createStatus: null,
  solveOrRejectStatus: null,
  customerTicket: [],
  customerDataLength: 0,
  customerTicketStatus: false,
};

const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // [1] get Tickets Thunk data
    builder.addCase(getTicketsThunk.fulfilled, (state, action) => {
      state.data = action.payload.data.map((v) => {
        !v.rate ? (v.rate = "لا يوجد تقيمات") : <span>{v.rate} </span>;

        // ticket status
        v.status = (
          <span className={`ticketStatus ${v.status}`}>
            {ticketStatusInArabic(v.status)}
          </span>
        );
        v.seen = !v.seen ? (
          <FontAwesomeIcon
            icon={faBell}
            className="text-red-500 transition-all duration-700 "
          />
        ) : (
          <FontAwesomeIcon icon={faCheckDouble} className="text-color-green" />
        );
        v.createdAt = new Date(v.createdAt).toLocaleDateString();
        // concat Button
        v.contact = (
          <Link
            onClick={() => setTicketSeenThunk(v)}
            to={`chat/${v.id}`}
            className=" btn btn--primary btn--m"
          >
            تواصل
          </Link>
        );
        return v;
      });
      state.loading = false;
      state.dataLength = action.payload.dataLength;
      state.status = response_status.success;
    });
    builder.addCase(getTicketsThunk.pending, (state, action) => {
      state.data = [];
      state.loading = true;
      state.status = response_status.none;
    });
    builder.addCase(getTicketsThunk.rejected, (state, action) => {
      state.loading = false;
      state.status = response_status.fail;
    });

    // [2] Set seen Status
    builder.addCase(setTicketSeenThunk.fulfilled, (state, action) => {
      state.seenStatus = response_status.success;
      state.loading = false;
    });
    builder.addCase(setTicketSeenThunk.pending, (state, action) => {
      state.seenStatus = response_status.none;
      state.loading = true;
    });
    builder.addCase(setTicketSeenThunk.rejected, (state, action) => {
      state.seenStatus = response_status.fail;
      state.loading = false;
    });

    // [3] create Ticket
    builder.addCase(createTicketThunk.fulfilled, (state, action) => {
      state.createStatus = response_status.success;
      state.loading = false;
    });
    builder.addCase(createTicketThunk.pending, (state, action) => {
      state.createStatus = response_status.none;
      state.loading = true;
    });
    builder.addCase(createTicketThunk.rejected, (state, action) => {
      state.createStatus = response_status.fail;
      state.loading = false;
    });
    // [6] solve or reject Ticket
    builder.addCase(solveOrRejectTicketThunk.fulfilled, (state, action) => {
      state.solveOrRejectStatus = response_status.success;
      state.loading = false;
    });
    builder.addCase(solveOrRejectTicketThunk.pending, (state, action) => {
      state.solveOrRejectStatus = response_status.none;
      state.loading = true;
    });
    builder.addCase(solveOrRejectTicketThunk.rejected, (state, action) => {
      state.solveOrRejectStatus = response_status.fail;
      state.loading = false;
    });
    // [6] Ticket by Customer Id
    builder.addCase(getTicketByCustomerId.fulfilled, (state, action) => {
      state.customerTicketStatus = response_status.success;
      state.loading = false;
      state.customerDataLength = action.payload.dataLength;
      state.customerTicket = action.payload.data.map((v) => {
        !v.rate ? (v.rate = "لا يوجد تقيمات") : <span>{v.rate} </span>;

        // ticket status
        v.status = (
          <span className={`ticketStatus ${v.status}`}>
            {ticketStatusInArabic(v.status)}
          </span>
        );
        v.seen = !v.seen ? (
          <FontAwesomeIcon
            icon={faBell}
            className="text-red-500 transition-all duration-700 animate-ping"
          />
        ) : (
          <FontAwesomeIcon icon={faCheckDouble} className="text-color-green" />
        );
        // concat Button
        v.contact = (
          <Link
            onClick={() => setTicketSeenThunk(v)}
            to={`/ticket/chat/${v.id}`}
            className=" btn btn--primary btn--m"
          >
            التذكره
          </Link>
        );
        return v;
      });
    });
    builder.addCase(getTicketByCustomerId.pending, (state, action) => {
      state.customerTicketStatus = response_status.none;
      state.loading = true;
    });
    builder.addCase(getTicketByCustomerId.rejected, (state, action) => {
      state.customerTicketStatus = response_status.fail;
      state.loading = false;
    });
  },
});

export default ticketsSlice.reducer;

export const ticketsAction = ticketsSlice.actions;

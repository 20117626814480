import React, {useEffect} from "react";
import {motion, useInView, useAnimation} from "framer-motion";

const Reveal = ({children, width = "fit-content"}) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, {once: true});
  const mainControls = useAnimation();
  const slideControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visable");
      slideControls.start("visable");
    }
  }, [isInView, mainControls, slideControls]);
  return (
    <div ref={ref} style={{position: "relative", overflow: "hidden"}}>
      <motion.div
        variants={{
          hidden: {opacity: 0, y: 75},
          visable: {opacity: 1, y: 0},
        }}
        initial="hidden"
        animate={mainControls}
        transition={{duration: 0.5, delay: 0.25}}
      >
        {children}
      </motion.div>
      {/* Slide Controls  */}
      <motion.div
        variants={{
          hidden: {left: 0},
          visable: {left: "100%"},
        }}
        initial="hidden"
        animate={slideControls}
        transition={{duration: 0.5, ease: "easeIn"}}
        style={{
          position: "absolute",
          top: 4,
          bottom: 4,
          left: 0,
          right: 0,
          background: "rgba(121, 121, 121, 0.06) ",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(5px)",
          WebkitBackdropFilter: "blur(5px)",

          zIndex: 20,
        }}
      />
    </div>
  );
};
export default React.memo(Reveal);

import React from "react";
import {Link} from "react-router-dom";

const FooterList = (props) => {
  return (
    <div className="container mx-auto">
      <ul className="flex items-center gap-2 flex-wrap lg:flex-nowrap">
        {props?.links?.map((link) => {
          return (
            <li className="p-2 rounded   bg-black" key={link.id}>
              <Link to={link.path} className=" text-white text-lg ">
                {link.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default React.memo(FooterList);

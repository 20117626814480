import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config";
import { userActions } from "../Slices/UserSlice";

export const getTrialsThunk = createAsyncThunk(
  "gettrials",
  async (payload, thunk) => {
    const state = thunk.getState();
    const userAuth = state.user.user.token;

    let response = null;
    try {
      response = await axios.get(`${BASE_URL}/trials`, {
        params: {
          skip: payload.skip,
          limit: payload.limit,
        },
        headers: {
          Authorization: `Bearer ${userAuth}`,
        },
      });
    } catch (err) {
      console.log(err);
      thunk.dispatch(userActions.login(null));
    }
    return response.data;
  }
);

export const postTrialsThunk = createAsyncThunk(
  "postTrials",
  async (payload) => {
    const response = await axios.post(`${BASE_URL}/trials`, payload);
    return response.data;
  }
);

import { createSlice } from "@reduxjs/toolkit";

import { response_status } from "../../Constant/Status";
import {
  addInstructionsThunk,
  deleteInstructionsThunk,
  editInstructionsThunk,
  getInstructionsThunk,
} from "../Thunk/InstructionsThunk";

const initialState = {
  loading: false,
  data: [],
  status: null,
  dataLength: 0,
};

const instructionsSlice = createSlice({
  name: "instructions",
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInstructionsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.status = response_status.success;
      state.dataLength = action.payload.dataLength;
    });
    builder.addCase(getInstructionsThunk.pending, (state, action) => {
      state.loading = true;
      state.data = [];
      state.status = response_status.none;
    });
    builder.addCase(getInstructionsThunk.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.status = response_status.fail;
    });

    // [2] Delete Instruction
    builder.addCase(deleteInstructionsThunk.fulfilled, (state, action) => {
      state.data = state.data.filter((item) => item.id !== action.payload);
      state.loading = false;
      state.status = response_status.success;
    });
    builder.addCase(deleteInstructionsThunk.pending, (state, action) => {
      state.loading = true;
      state.status = response_status.none;
    });
    builder.addCase(deleteInstructionsThunk.rejected, (state, action) => {
      state.loading = false;
      state.status = response_status.fail;
    });

    // [3] Delete Instruction
    builder.addCase(addInstructionsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.status = response_status.success;
      state.data = state.data.filter((item) => item.id !== action.payload);
    });
    builder.addCase(addInstructionsThunk.pending, (state, action) => {
      state.loading = true;
      state.status = response_status.none;
    });
    builder.addCase(addInstructionsThunk.rejected, (state, action) => {
      state.loading = false;
      state.status = response_status.fail;
    });

    // [4] edit Instruction
    builder.addCase(editInstructionsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = state.data.map((v) => {
        if (action.payload.id) {
          return action.payload.data;
        }
        return v;
      });
      state.status = response_status.success;
    });
    builder.addCase(editInstructionsThunk.pending, (state, action) => {
      state.loading = true;
      state.status = response_status.none;
    });
    builder.addCase(editInstructionsThunk.rejected, (state, action) => {
      state.loading = false;
      state.status = response_status.fail;
    });
  },
});

export default instructionsSlice.reducer;
export const instructionAction = instructionsSlice.actions;

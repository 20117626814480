import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { BASE_URL } from "../config";

const useFileUpload = (url, uploadFileName) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("حمل صوره");
  const [progress, setProgress] = useState({ started: false, pc: 0 });
  const [data, setData] = useState(null);
  const userToken = useSelector((state) => state.user?.user?.token);

  const uploadFile = async (files) => {
    if (!files.length) {
      setLoading(false);
      return;
    }
    const form = new FormData();

    if (files) {
      for (let f of files) {
        form.append("files", f);
      }
      setMessage("برجاء لانتظار...");
      setProgress((prevState) => {
        return { ...prevState, started: true };
      });

      await axios
        .post(BASE_URL + url, form, {
          onUploadProgress: (progressEvent) => {
            setProgress((prev) => {
              return { ...prev, pc: progressEvent.progress * 100 };
            });
          },
          headers: {
            Authorization: `Bearer ${userToken} `,
          },
        })
        .then((res) => {
          setMessage("تم الرفع بنجاح");
          setData({ [uploadFileName]: res.data[0].path });
          setLoading(false);
        })
        .catch((err) => {
          setMessage(err.message);
          console.log(err);
          setLoading(false);
        });
    } else {
      setMessage("يجب اختيار صوره");
    }
  };
  return {
    loading,
    data,
    setData,
    message,
    progress,
    uploadFile,
  };
};

export default useFileUpload;

import client1 from "../Assets/1.png";

import client3 from "../Assets/3.png";
import client4 from "../Assets/4.png";

import client6 from "../Assets/6.jpeg";
import client7 from "../Assets/7.png";
import client8 from "../Assets/8.jpg";
import client9 from "../Assets/9.png";
import client10 from "../Assets/10.png";
import client11 from "../Assets/1.png";
import client12 from "../Assets/11.png";
import client13 from "../Assets/12.png";
import client14 from "../Assets/13.png";
import client15 from "../Assets/14.jpeg";
export const OurClientsData = [
  client1,
  client3,
  client4,
  client6,
  client7,
  client8,
  client9,
  client10,
  client11,
  client12,
  client13,
  client14,
  client15,
];

import React from "react";
import Button from "../Button/Button";
import {Link} from "react-router-dom";

const Value = (props) => {
  return (
    <section className="grid grid-cols-1 lg:grid-cols-2 place-items-center  ">
      <div className="px-5">
        <h2 className="value-right__title">{props?.title}</h2>
        <p className="value-right__description">{props?.description}</p>
        <Link path="/freetrial" className="btn btn--l btn--primary">
          طلب نسخة تجريبية
        </Link>
      </div>
      <div className="">
        <img src={props?.img} alt={props?.title} />
      </div>
    </section>
  );
};

export default React.memo(Value);

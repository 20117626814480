import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config";
import { userActions } from "../Slices/UserSlice";

// dispatch(getProductsThunk({skip:0,limit:10}));

export const getProductsThunk = createAsyncThunk(
  "getProducts",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    const response = await axios.get(BASE_URL + "/products", {
      params: {
        skip: payload.skip,
        limit: payload.limit,
      },
      headers: {
        Authorization: `Bearer ${AuthToken}`,
      },
    });

    return response.data;
  }
);

export const getProductByIdThunk = createAsyncThunk(
  "getProductById",
  async (id, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    const response = await axios.get(BASE_URL + `/products/${id}`, {
      headers: {
        Authorization: `Bearer ${AuthToken}`,
      },
    });

    return response.data;
  }
);

export const updateProductByIdThunk = createAsyncThunk(
  "updateProductById",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    let _response = null;
    try {
      // eslint-disable-next-line no-unused-vars
      _response = await axios.put(
        BASE_URL + `/products/${payload.id}`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${AuthToken}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
    return payload;
  }
);

export const deleteProductThunk = createAsyncThunk(
  "deleteProduct",
  async (id, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    let response = null;

    try {
      response = await axios.delete(BASE_URL + `/products/${id}`, {
        headers: {
          Authorization: `Bearer ${AuthToken}`,
        },
      });
    } catch (err) {
      console.log(err);
      thunk.dispatch(userActions.login(null));
    }
    return response.data;
  }
);

export const addProductThunk = createAsyncThunk(
  "addProduct",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    let response = null;
    try {
      response = await axios.post(
        BASE_URL + `/products`,
        { ...payload.data, ...payload.upload },
        {
          headers: {
            Authorization: `Bearer ${AuthToken}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
      thunk.dispatch(userActions.login(null));
    }
    return response.data;
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config";

export const getFAQThunk = createAsyncThunk(
  "getFaq",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;

    const response = await axios.get(`${BASE_URL}/faq`, {
      params: {
        skip: payload.skip,
        limit: payload.limit,
      },
      headers: {
        Authorization: `Bearer ${AuthToken}`,
      },
    });
    return response.data;
  }
);

export const deleteFaqThunk = createAsyncThunk(
  "deleteFaq",
  async (id, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    let response = null;
    try {
      response = await axios.delete(`${BASE_URL}/faq/${id}`, {
        headers: {
          Authorization: `Bearer ${AuthToken}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
    return response.data;
  }
);

export const createFaqThunk = createAsyncThunk(
  "createFaq",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    let response = null;
    try {
      response = await axios.post(`${BASE_URL}/faq`, payload, {
        headers: {
          Authorization: `Bearer ${AuthToken}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
    return response.data;
  }
);

import React from "react";
import {HashRouter as Router} from "react-router-dom";
import AnimatedRoutes from "../AnimatedRoutes/AnimatedRoutes";
import WhatsappButton from "../components/WhatsappButton/WhatsappButton";
import TiktokPixel from "tiktok-pixel";

const date = new Date();
function App() {
  React.useEffect(() => {
    const advancedMatching = {
      email: process.env.REACT_APP_SNAPCHAT_EMAIL,
      phone_number: process.env.REACT_APP_PHONE_NUMBER ?? "+966546811900",
    };
    const options = {
      debug: true, // enable logs
    };
    TiktokPixel.init(
      process.env.REACT_APP_TIKTOK_TOKEN,
      advancedMatching,
      options,
    );

    TiktokPixel.pageView();
    TiktokPixel.track("HomePage", {
      "Home page visited":
        date.toLocaleTimeString() + "/" + date.toLocaleDateString(),
    });
    // TiktokPixel.track("track", {});
  }, []);
  return (
    <main className="App">
      <Router>
        <WhatsappButton />
        <AnimatedRoutes />
      </Router>
    </main>
  );
}

export default App;

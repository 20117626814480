import React, {Suspense} from "react";
// images and FontAwesome
import {imac} from "../../Images";
import {
  faUser,
  faCompass,
  faEye,
  faFaceSmile,
  faChartSimple,
  faPhone,
  faEnvelope,
  faLocation,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
// Redux and helpers
import {useDispatch, useSelector} from "react-redux";
import Reveal from "../../components/Reveal";
import {getMetaData} from "../../Redux/Thunk/metaDataThunk";
// Components
import Value from "../../components/Value";
import Counter from "../../components/Counter/Counter";
import GoalsItem from "../../components/GoalsItem";
import ContactItem from "../../components/ContactItem";
import Loading from "../../components/Loading/Loading";
import ProductCard from "../../components/ProductCard/ProductCard";
import Header from "../../components/Header";
import OurClients from "../../components/OurClients";
import {
  deleteProductThunk,
  getProductsThunk,
  updateProductByIdThunk,
} from "../../Redux/Thunk/ProductThunk";
import {productAction} from "../../Redux/Slices/ProductSlice";
import useAlert from "../../hooks/useAlert";
import {OurClientsData} from "../../Data/OurClientsData";

const Home = () => {
  const dispatch = useDispatch();
  const metaData = useSelector((state) => state?.metaData?.data?.[0]);
  const {data, deleteProductStatus, editProductStatus, loading} = useSelector(
    (state) => state.products,
  );
  // Get Products Data
  React.useEffect(() => {
    dispatch(getProductsThunk({skip: 0, limit: 20}));
  }, [dispatch]);
  // Get Meta Data
  React.useEffect(() => {
    if (!metaData) {
      dispatch(getMetaData());
    }
  }, [dispatch, metaData]);

  const [productEditableContent, setProductEditableContent] = React.useState({
    body: {},
    editable: false,
  });

  const productEditContent = React.useCallback(
    (id, data) => {
      dispatch(productAction.editProduct({id, data}));
    },
    [dispatch],
  );

  const onProductSubmit = React.useCallback(
    (id, data) => {
      setProductEditableContent((old) => ({
        ...old,
        editable: !old.editable,
      }));
      dispatch(updateProductByIdThunk({id, data}));
    },
    [dispatch],
  );

  useAlert(
    deleteProductStatus,
    "success",
    "error",
    "تم الإلغاء",
    "فشل في الإلغاء",
    3000,
  );
  useAlert(
    editProductStatus,
    "success",
    "error",
    "تم التعديل",
    "فشل في التعديل",
    3000,
  );

  return (
    <main className="home">
      <Reveal>
        <Header />
      </Reveal>
      {/* Value Section */}

      <section className="home-value">
        <Value
          img={imac}
          title="القيمة التى تحصل عليها عند استخدام برامجنا"
          description="تقوم الشركة بتقديــم حلــول الأعمــال للمؤسســات والشــركات مــن خلال
              الفهــم الدقيــق لإحتياجــات العــملاء حيــث نوفــر برامــج محاســبية ســهلة
              الإســتخدام ومرنــة فــي إدارة وتنظيــم الحســابات ودقــة
              التقاريــر ممــا يحقــق أهــداف المؤسســات والشــركات كمــا
              يســتمر فريــق العمــل بتطويــر النظــام بشــكل دوري
              ومســتمر بالإضافــة إلــى تصميــم الأنظمــة والبرامــج
              المتخصصـة وفقـاً لمتطلبـات العمـل وذلـك لتوافـر مطـوري
              البرامـج لتصميـم أيـة أنظمـة فـي كافـة المجـالات المختلفـة
              مــع توافــر الدعــم الفنــى علــى مــدار الســاعة ليبقــى دائمــاً
              البرنامج الرائد فى عالم البرمجيات المحاسبية العربية معتمد لدى هيئة الزكاة و الضريبة و الهيئة العامة للغذء و الدواء
              "
        />
      </section>

      {/* Our Clients Section */}
      <Reveal>
        <section className="home-clients" id="clients">
          <Suspense fallback={<Loading />}>
            <OurClients
              title={"انضم الى"}
              coloredTitle="عملائنا"
              description="تقدم الشركة نظام محاسبي متكامل شامل حسابات التكاليف يدعم الفاتورة الالكترونية
              يمكن العميل من متابعة ومراقبة حسابات جميع الفروع
              حيث توفر ادارة مركزية للفروع مع تحكم كامل بالمخزون 
              مع تحكم لامحدود بصلاحيات المستخدمين للنظام 
                 كما تتيح للعميل تقارير تفصيلية لمتابعة العمل بشكل مستمر لذا كونت شركة وافي القمة قائمة بالعملاء التي تفخر بهم من شتى انحاء المملكة كما تسعى جادة الى التوسع في تقديم خدماتها إقليمياً وحرصا على استمرارية العلاقة مع العملاء و كسب رضاهم فقد تم العمل على تقديم خدمات ما بعد البيع على اعلى مستوى من خلال فريق احترافي متخصص."
              imgs={OurClientsData}
            />
          </Suspense>
        </section>
      </Reveal>
      {/* Goals Section */}
      <Reveal>
        <section className="bg-black/75 py-20">
          <div className="container max-w-[1080px] mx-auto">
            <h2 className="text-5xl text-white font-semibold text-center  ">
              من نحن
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 items-center gap-10">
              <GoalsItem
                title="رسالتنا"
                icon={faCompass}
                text="تلبّيةً احتياجات كافة العملاء ، والتأكيد على تقديم خدمات البيع وما بعد البيع، مع المحافظة على تحقيق مستوى متميز ومرضي لجميع العملاء بكل مصداقية وشفافية"
              />
              <GoalsItem
                title="أهدافنا"
                icon={faEye}
                text="نسعى لنكون مقدم الخدمات البرمجية الأكثر موثوقية في المملكة والاكثر ارضاءا لجميع العملاء ."
              />
              <GoalsItem
                title="خدماتنا"
                icon={faGear}
                text="تقديــم حلــول الأعمــال للمؤسســات والشــركات مــن خلال
                الفهــم الدقيــق لإحتياجــات العملاء و توفير برامج محاسبية و إداريه تساهم فى تطوير أليه العمل داخل المؤسسة ."
              />
            </div>
          </div>
        </section>
      </Reveal>
      {/* satisfaction */}
      <Reveal>
        <section className="home-counter">
          <Counter
            percentage
            icon={faFaceSmile}
            title="رضى العملاء"
            from={0}
            to={Number(metaData?.satisfaction_rate ?? 97)}
          />
          <Counter
            icon={faUser}
            title="عملائنا"
            from={0}
            to={Number((metaData?.customers ?? 0) + 2500)}
          />
          <Counter
            icon={faChartSimple}
            title="الزيارات"
            from={0}
            to={Number((metaData?.visitors ?? 0) + 10000)}
          />
        </section>
      </Reveal>
      {/* Products Sectoion */}
      <Reveal>
        <section className="products" id="products">
          <div className="products__title">
            <h2 className="products__title-top">منتجاتنا</h2>
            <p className="products__title-bottom">
              افضـل البرامـج الموجـوده بالمملكـة فيمكنـك لإنشــاء تقاريــر أو
              ســحابياً اســتخدامه محليــاً نشاطك التجاري
            </p>

            <>
              {loading && <Loading />}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3  container mx-auto max-w-[900px] ">
                {data?.map((product) => {
                  return (
                    <ProductCard
                      editable={productEditableContent.editable}
                      product={product}
                      key={product.id}
                      btnTitle={product.btnTitle}
                      onEdit={productEditContent}
                      handleEdit={() => {
                        setProductEditableContent((old) => {
                          return {...old, editable: !old.editable};
                        });
                      }}
                      onDelete={() => {
                        dispatch(deleteProductThunk(product.id));
                      }}
                      onSubmit={onProductSubmit}
                    />
                  );
                })}
              </div>
            </>
          </div>
        </section>
      </Reveal>
      {/* CONTACT US SECTION */}
      <Reveal>
        <section className="home-contact">
          <div className="home-contact-right">
            <h2 className="home-contact-right__title">
              تواصل <span>معنا</span>
            </h2>
            <p className="home-contact-right__description">
              يمكنك التواصل معنا عبر العديد من منصات التواصل الإجتماعى
            </p>
          </div>
          <div className="home-contact-left">
            <div className="home-contact-left__items">
              <ContactItem
                textAlign="center"
                yAnimate={0}
                yInitial={100}
                icon={faPhone}
                title="الهاتف"
                desc="054-681-1900"
                secDesc="017-221-1900"
                path="tel:+966546811900"
                column
              >
                <div className="text-white text-2xl whitespace-nowrap ">
                  <p>054-681-1900</p>
                  <p>017-221-1900</p>
                </div>
              </ContactItem>
              <ContactItem
                textAlign="center"
                yAnimate={0}
                yInitial={100}
                icon={faEnvelope}
                title="البريد الآلكترونى"
                desc="programtech@wafitop.com"
                path="mailto:programtech@wafi.com"
                column
              />
              <ContactItem
                textAlign="center"
                yAnimate={0}
                yInitial={100}
                icon={faLocation}
                path="https://goo.gl/maps/TnnxeYVzjBqNTqQm6"
                title="العنوان"
                column
              >
                <div className="text-white text-2xl whitespace-nowrap ">
                  <p>الرياض: طريق الملك عبدالعزيز</p>
                  <p>خميس مشيط: طريق الملك فهد</p>
                </div>
              </ContactItem>
            </div>
          </div>
        </section>
      </Reveal>
    </main>
  );
};

export default Home;

import { createSlice } from "@reduxjs/toolkit";
import { getMetaData } from "../Thunk/metaDataThunk";
const initialState = {
  loading: false,
  data: [],
};
const metaSlice = createSlice({
  name: "metaData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMetaData.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(getMetaData.pending, (state, action) => {
      state.data = [];
      state.loading = true;
    });
    builder.addCase(getMetaData.rejected, (state, action) => {
      state.data = [];
      state.loading = false;
    });
  },
});

export default metaSlice.reducer;
export const metaActions = metaSlice.actions;

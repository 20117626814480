import React from "react";
import { response_status } from "../Constant/Status";
import Swal from "sweetalert2";
// title: "اسم المستخدم او كلمه المرور غير صحيحه",
export default function useAlert(
  status,
  swal_icon_success,
  swal_icon_fail,
  swal_title_success,
  swal_title_fail,
  timeout,
  onClose,
  cancelBtn
) {
  React.useEffect(() => {
    switch (status) {
      case response_status.success:
        Swal.fire({
          icon: swal_icon_success,
          title: swal_title_success,
          showCancelButton: false,
          showCloseButton: false,
        });
        break;
      case response_status.fail:
        Swal.fire({
          icon: swal_icon_fail,
          title: swal_title_fail,
          showCancelButton: false,
          showCloseButton: false,
        });
        break;
      case response_status.none:
      default:
    }

    setTimeout(() => {
      Swal.close({ dismiss: Swal.DismissReason.timer });
      onClose && onClose();
    }, timeout);

    // return function swal_effect_cleanup() {
    //   clearTimeout(id);
    // };
  }, [
    status,
    swal_icon_fail,
    swal_icon_success,
    swal_title_fail,
    swal_title_success,
    timeout,
    onClose,
  ]);
}

import { createSlice } from "@reduxjs/toolkit";
import { loginThunk, refreshTokenThunk } from "../Thunk/UserThunk";
import { response_status } from "../../Constant/Status";
import { singOutThunk } from "../Thunk/SingOutThunk";

const initialState = {
  user: {},
  status: response_status.none,
  loading: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: function (state, action) {
      state.roles.push("");
    },
    setStatus: function (state, action) {
      state.status = action.payload;
    },
    logOut: function (state, action) {
      state.user = {};
      state = { ...initialState };
      window.localStorage.clear();
      singOutThunk();
    },
    login: function (state, action) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      state.status = response_status.success;
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(loginThunk.pending, (state, action) => {
      state.loading = true;
      state.status = response_status.none;
    });
    builder.addCase(loginThunk.rejected, (state, action) => {
      state.user = {};
      state.status = response_status.fail;
      state.loading = false;
    });

    // Refresh Token

    builder.addCase(refreshTokenThunk.fulfilled, (state, action) => {
      state.status = response_status.success;
      state.loading = false;
      state.user.token = action.payload.token;
    });
    builder.addCase(refreshTokenThunk.pending, (state, action) => {
      state.loading = false;
      state.status = response_status.none;
    });
    builder.addCase(refreshTokenThunk.rejected, (state, action) => {
      state.status = response_status.fail;
      state.loading = false;
    });
  },
});

export default userSlice.reducer;

export const userActions = userSlice.actions;

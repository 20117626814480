import {createSlice} from "@reduxjs/toolkit";
import {contactThuhk, getContactThunk} from "../Thunk/ContactThunk";
import {response_status} from "../../Constant/Status";

const initialState = {
  data: [],
  status: null,
  selectedItem: null,
  loading: false,
  dataLength: 0,
  chosenPlan: "",
};
const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    setChosenPlan: (state, action) => {
      state.chosenPlan = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(contactThuhk.fulfilled, (state, action) => {
      state.loading = false;
      state.status = response_status.success;
    });
    builder.addCase(contactThuhk.pending, (state, action) => {
      state.loading = true;
      state.status = response_status.none;
    });
    builder.addCase(contactThuhk.rejected, (state, action) => {
      state.loading = false;
      state.status = response_status.fail;
    });
    // [2] get contact
    builder.addCase(getContactThunk.fulfilled, (state, action) => {
      const modifiedData = action.payload.data.map((item) => {
        item.createdAt = new Date().toLocaleDateString();

        return item;
      });
      state.data = modifiedData;
      state.loading = false;
      state.status = response_status.success;
      state.dataLength = action.payload.dataLength;
    });
    builder.addCase(getContactThunk.pending, (state, action) => {
      state.loading = true;
      state.status = response_status.none;
    });
    builder.addCase(getContactThunk.rejected, (state, action) => {
      state.data = [];
      state.loading = false;
      state.status = response_status.fail;
    });
  },
});

export default contactSlice.reducer;
export const contactAction = contactSlice.actions;

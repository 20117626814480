import React from "react";
import "./loading.scss";
const Loading = () => {
  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
};

export default React.memo(Loading);

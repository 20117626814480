import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { lazy, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallBack from "../pages/ErrorFallBack/ErrorFallBack";
import ROUTES from "../Routes/Routes";
import ProtectedRoutes from "../ProtectedRoutes/";
import PagesLayout from "../layouts/PagesLayout";
const PageNotFound = lazy(() => import("../pages/NotFound"));

const AnimatedRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // setting location to animate pages

  return (
    <AnimatePresence>
      <PagesLayout>
        <Routes location={location} key={location.pathname}>
          {/* The Protected Routes */}
          <Route element={<ProtectedRoutes />}>
            {ROUTES?.map((route) => {
              return (
                route.requires && (
                  <Route
                    key={route.id}
                    path={route.path}
                    element={
                      <ErrorBoundary
                        FallbackComponent={ErrorFallBack}
                        onReset={() => navigate("/")}
                      >
                        <Suspense>{<route.Element />} </Suspense>
                      </ErrorBoundary>
                    }
                  />
                )
              );
            })}
          </Route>

          {/* None Protected Routes */}
          {ROUTES?.map((route) => {
            return (
              <Route
                key={route.id}
                index={route.index}
                path={route.path}
                element={
                  <ErrorBoundary
                    FallbackComponent={ErrorFallBack}
                    onReset={() => navigate("/")}
                  >
                    <Suspense>{<route.Element />}</Suspense>
                  </ErrorBoundary>
                }
              />
            );
          })}

          {/* 404 or Page Not Found */}
          <Route
            path="*"
            element={
              <ErrorBoundary
                FallbackComponent={ErrorFallBack}
                onReset={() => navigate("/")}
              >
                <Suspense>{<PageNotFound />}</Suspense>
              </ErrorBoundary>
            }
          />
        </Routes>
      </PagesLayout>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;

import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../../config";
import {ROLES} from "../../Constant/Roles";
import {refreshTokenThunk} from "./UserThunk";
import {userActions} from "../Slices/UserSlice";

export const getTicketsThunk = createAsyncThunk(
  "getTickets",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    const {role, userId} = state.user.user.user;
    let response = null;
    try {
      response = await axios.get(
        `${BASE_URL}/tickets/${role}/${role === ROLES.ADMIN ? "" : userId}`,
        {
          params: {
            skip: payload.skip,
            limit: payload.limit,
          },
          headers: {
            Authorization: `Bearer ${AuthToken}`,
          },
        },
      );
    } catch (err) {
      console.log(err);
      if ([401, 403].includes(err.response.status)) {
        thunk.dispatch(refreshTokenThunk());
      }
    }
    return response.data;
  },
);

export const setTicketSeenThunk = createAsyncThunk(
  "set-seen-ticket",
  async (id, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    const {role} = state.user.user.user;
    let response = null;
    try {
      if (role === ROLES.ADMIN || role === ROLES.EMPLOYER) {
        response = await axios.put(
          `${BASE_URL}/tickets/${id}`,
          {seen: true},
          {
            headers: {
              Authorization: `Bearer ${AuthToken}`,
            },
            withCredentials: true,
          },
        );
      }
    } catch (err) {
      console.log(err);
      thunk.dispatch(userActions.login(null));
    }
    return response.data;
  },
);

export const createTicketThunk = createAsyncThunk(
  "create-ticket",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    const {userId} = state.user.user.user;

    let response = null;

    try {
      response = await axios.post(
        `${BASE_URL}/tickets`,
        {
          ...payload,
          customer_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${AuthToken}`,
          },
        },
      );
    } catch (err) {
      console.log(err);
      return err;
      // thunk.dispatch(userActions.login(null));
    }
    return response.data;
  },
);

export const solveOrRejectTicketThunk = createAsyncThunk(
  "solve-or-reject-ticket",
  async (payload, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    const {role} = state.user.user.user;
    let response = null;

    try {
      if (role === ROLES.ADMIN || role === ROLES.EMPLOYER) {
        response = await axios.put(
          `${BASE_URL}/tickets/${payload.id}`,
          {status: payload.status},
          {
            headers: {
              Authorization: `Bearer ${AuthToken}`,
            },
          },
        );
      }
    } catch (err) {
      console.log(err);
      thunk.dispatch(userActions.login(null));
    }
    return response.data;
  },
);

export const getTicketByCustomerId = createAsyncThunk(
  "getTicketbyCustomerId",
  async (id, thunk) => {
    const state = thunk.getState();
    const AuthToken = state.user.user.token;
    let response = null;
    try {
      response = await axios.get(`${BASE_URL}/tickets/customer/${id}`, {
        headers: {
          Authorization: `Bearer ${AuthToken}`,
        },
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
      thunk.dispatch(userActions.login(null));
    }
    return response.data;
  },
);

import "./progress.scss";
import { memo } from "react";

const ProgressBar = ({ progress, background }) => {
  const progressValue = Math.floor(progress).toFixed();
  return (
    <div className="progressBar">
      <div
        className="progressBar__progress"
        style={{ width: progressValue + "%", backgroundColor: background }}
      ></div>
      <span className="progressBar__text">{progressValue}%</span>
    </div>
  );
};

export default memo(ProgressBar);

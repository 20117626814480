import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { useLocation } from "react-router-dom";

const PagesLayout = ({ children }) => {
  const location = useLocation();
  // pages Routes Starts with admin or / admin/*
  const regex = /(\/admin|\/admin.+)/g;
  const isAdmin = location.pathname.match(regex);

  if (!isAdmin) {
    return (
      <main className="pagesLayot">
        <Navbar />
        <div className="mainLayout">{children}</div>
        <Footer />
      </main>
    );
  } else {
    return children;
  }
};

export default PagesLayout;

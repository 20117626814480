import React from "react";
import ProgressBar from "../ProgressBar/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { BASE_URL } from "../../config";
import { undefinedImage } from "../../Images";

const ImageUploadComponent = ({ loading, msg, img, onChange, progress }) => {
  return (
    <div className="addProduct__upload">
      <label htmlFor="upload" className="addProduct__upload__image">
        <img
          className="addProduct__upload__image-img"
          crossOrigin="true"
          alt="product"
          src={img ? `${BASE_URL.replace("/api", "")}${img}` : undefinedImage}
        />

        <div className="addProduct__upload__image__title">
          <FontAwesomeIcon
            icon={faUpload}
            className="addProduct__upload__image-icon"
          />
          <p className="addProduct__upload__image__title-text">{msg}</p>
        </div>
        {progress.started && (
          <ProgressBar background={"#e1c455"} progress={progress.pc} />
        )}
      </label>

      <input
        disabled={loading}
        id="upload"
        type="file"
        multiple
        className="addProduct__upload__input"
        onChange={(e) => onChange(e.target.files)}
      />
    </div>
  );
};

export default React.memo(ImageUploadComponent);
